import Icon, { IconIds, IconProps } from '@/components/atoms/legacy/Icon';
import { FC, useEffect } from 'react';

interface TitleProps {
  onClose: () => void;
  className?: string;
  title?: string;
  icon?: IconProps;
  closeIconSize?: number;
}
export interface PopOutModalProps extends TitleProps {
  children: React.ReactNode;
  titleClassName?: string;
  open: boolean;
  otherModalProps?: object;
  left?: boolean;
  variant?: 'dialog' | 'slide-out';
  onAnimationEnd?: () => void;
}

const dialogTitle = ({
  onClose,
  className = '',
  title,
  icon = { iconId: IconIds.XMark, className: '' },
  closeIconSize,
}: TitleProps) => {
  return (
    <div
      className={`align-center flex ${
        title && title.length > 0 ? 'justify-between' : 'justify-end'
      } px-xl pb-m pt-xl text-body2Light ${className}`}
    >
      {title && title.length > 0 && <div className="text-start">{title}</div>}
      <button onClick={onClose} className={'pointer-cursor'}>
        <Icon {...icon} width={closeIconSize} height={closeIconSize} />
      </button>
    </div>
  );
};

const slideOutTitle = ({
  onClose,
  className = '',
  title,
  icon = { iconId: IconIds.XMark, className: '' },
  closeIconSize,
}: TitleProps) => {
  return (
    <div className={`flex w-full py-m ${className}`}>
      {title && <div className="w-full text-h3SemiBold">{title}</div>}
      <div className="flex w-full justify-end">
        <button onClick={onClose} className="pointer-cursor">
          <Icon {...icon} width={closeIconSize} height={closeIconSize} />
        </button>
      </div>
    </div>
  );
};

const PopOutModal: FC<PopOutModalProps> = ({
  otherModalProps,
  title = '',
  className = '',
  icon = { iconId: IconIds.XMark, className: '' },
  closeIconSize,
  titleClassName = '',
  left,
  variant = 'dialog',
  children,
  onAnimationEnd,
  ...props
}) => {
  const { open, onClose } = props;

  useEffect(() => {
    function handleEscape(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        onClose();
      }
    }
    if (open) {
      document.documentElement.style.overflow = 'hidden';
      document.addEventListener('keydown', handleEscape);
    } else {
      document.documentElement.style.overflow = '';
    }

    return () => {
      document.documentElement.style.overflow = '';
      document.removeEventListener('keydown', handleEscape);
    };
  }, [open, onClose]);

  let containerClassName =
    'fixed left-0 top-0 z-50 h-full w-full bg-black bg-opacity-50';

  let modalClassName = `absolute left-[50%] top-[50%] z-50 flex h-auto w-full -translate-x-1/2 -translate-y-1/2 flex-col overflow-y-scroll rounded-small bg-sleetGrey-95 p-10 shadow-[4px_4px_30px_rgba(44,72,66,0.3)] m:w-[60%] ${className}`;

  if (variant === 'slide-out') {
    containerClassName = `fixed left-0 top-0 z-50 flex h-full w-full ${
      left ? 'justify-start' : 'justify-end'
    } bg-black bg-opacity-50 backdrop-blur-[2px]`;
    modalClassName = `flex h-[100vh] w-full flex-col overflow-y-scroll bg-neutral-0 py-[80px] pl-3xl pr-[80px] m:w-[620px] ${className}`;
  }

  return (
    <>
      {open && (
        <div className={containerClassName} onClick={onClose}>
          <div
            className={modalClassName}
            onAnimationEnd={onAnimationEnd}
            onClick={(e) => e.stopPropagation()}
            {...otherModalProps}
          >
            {variant === 'dialog'
              ? dialogTitle({
                  onClose,
                  title,
                  icon,
                  closeIconSize,
                  className: titleClassName,
                })
              : slideOutTitle({
                  onClose,
                  title,
                  icon,
                  closeIconSize,
                  className: titleClassName,
                })}
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default PopOutModal;
